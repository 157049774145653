.body-wrapper {
    padding: 0;
}

.icon {
    font-size: 48px;
}

.button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px #d4d4d4 solid;
    padding: 20px 0;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    color: unset;
    text-decoration: none !important;
}
