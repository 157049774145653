.button-shipping {
    border: 1px solid #000;
    padding: 7px 15px;
    width: fit-content;
    margin-left: 15px;
    cursor: pointer;
}

.button-shipping p {
    margin: 0;
    font-size: 14px;
    color: #000;
}

.selected-button-shipping {
    padding: 7px 15px;
    width: fit-content;
    margin-left: 15px;
    background-color: #000000;
    cursor: pointer;
}

.selected-button-shipping p {
    margin: 0;
    font-size: 14px;
    color: #fff;
}

.disabled-button-shipping {
    padding: 7px 15px;
    width: fit-content;
    margin-left: 15px;
    background-color: #ccc;
    cursor: not-allowed;
}

.disabled-button-shipping p {
    margin: 0;
    font-size: 14px;
    color: #fff;
}

.required-remark {
    font-size: 12px;
    font-style: italic;
}
