.span-as-button {
    color: #20a8d8;
    cursor: pointer;
    transition: color 0.3s;
}

.span-as-button:hover {
    color: #167495;
    text-decoration: underline;
    background-color: transparent;
}

.refresh-btn {
    cursor: pointer;
}
