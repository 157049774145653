body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
body .app.full-screen{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.app.full-screen header.app-header{
  top: -55px;
}
.app.full-screen .app-body{
  margin-top: 0;
}
.countdown-wrapper {
	display: flex;
	/* align-items: center;
	justify-content: center; */
  flex-wrap: wrap;
  padding: 40px 0;
}
.countdown-item {
	color: #ff0200;
	font-size: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
  line-height: 25px;
	/* margin: 10px; */
  padding-top: 10px;
	position: relative;
	width: 35px;
	/* height: 100px; */
}
.countdown-item span {
	color: #333;
	font-size: 6px;
	font-weight: 600;
	text-transform: uppercase;
}
.countdown-svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	height: 100px;
}
.app-header.navbar .navbar-brand{
  background-image: none;
}
.app-header.navbar .navbar-brand img{
  width: 65px;
  display: block;
  margin: 0 auto;
}
.Select .Select-control,.Select .Select-menu-outer{
  border-radius: 0;
}
.Select.kickAveReactSelect .Select-menu{
  max-height: 110px;
}

.Select.customSelect .Select-control{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.Select.customSelect .Select-control .Select-clear-zone{
  position: absolute;
  right: 25px;
}
.Select.customSelect .Select-control .Select-arrow-zone{
  position: absolute;
  right: 0px;
}
.Select.customSelect .Select-control .Select-multi-value-wrapper{
  height: 100%;
}

.loading-wrapper{
  display: block;
}
  .loading-wrapper.loading-wrapper--flex{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }
  .loading-wrapper > .loading-center{
    margin: 5px auto;
  }

  .link-secondary,
  .link-secondary:active,
  .link-secondary:hover,
  .link-secondary:focus{
    color: #263238;
  }

.text-bold{
  font-weight: 700;
}
.text-block{
  display: block;
}
.text-sm{
  font-size: 0.6rem;
}
.modal .modal-header h4{
  font-size: 1rem;
}

.filter-container{
  display: flex;
  flex-direction: column;
}
.filter-container__card-body{
  padding-bottom: 0rem;
}
.filter-container__card-body.card{
  border: none;
  margin-bottom: 0rem;
}
.filter-container__card-body.card hr{
  flex: 1;
  width: 100%;
}
.filter-container__toolbars{
  flex:1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: space-between;
}
.filter-container__toolbars .filter-container__toolbars__btn{
  margin-right: 10px;
}
.filter-container__toolbars .filter-container__toolbars__btn:last-child{
  margin-right: 0px;
}

.card-header.with-toolbars{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-header.with-toolbars > .with-toolbars__title,
.card-header.with-toolbars > .with-toolbars__toolbars{
  flex: 1 1 auto;
}
/* .card-header.with-toolbars > .with-toolbars__title{
  font-size: 16px;
} */
.card-header.with-toolbars{
  padding-bottom: 0.5rem;
}
.card-header.with-toolbars > .with-toolbars__title span{
  margin-left: 0.5rem;
  font-weight: bold;
  text-transform: uppercase
}
.card-header--bold{
  font-weight: bold;
  text-transform: uppercase
}
.toolbars{
  margin-bottom: 0.75rem;
}
.card-header.with-toolbars > .with-toolbars__toolbars,
.toolbars {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: space-between;
}
.with-toolbars__toolbars .with-toolbars__toolbars__btn,
.toolbars .toolbars__btn{
  margin-right: 0.25rem;
}
.with-toolbars__toolbars .with-toolbars__toolbars__btn:last-child,
.toolbars .toolbars__btn:last-child{
  margin-right: 0px;
}

.form-inline.form-filter{
  padding-bottom: 15px;
}
.form-inline.form-filter.with-border-bottom{
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.table .btn.btn-link{
  padding: 0;
}
.table tr th.v-center,
.table tr td.v-center{
  vertical-align: middle
}

.pagination-summaries{
  padding: 0.5rem 0rem;
  border-top: 1px solid rgb(221, 221, 221);
  margin-bottom: 0rem;
  font-size: 0.8rem;
}

.print-order-pagination{
  display: flex;
  justify-content:space-between;
  padding:0.5rem 0;
  border-top: 1px solid #ddd;
  margin-bottom: 0;
  font-size: .8rem;
  margin-top: 1rem;
  align-items: center;
}

.print-order-pagination .print-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.print-order-checkbox{
  width: 1rem;
  height: 1rem;
  border-radius: 0.2rem;
  border: 1px solid #ccc;
  margin-right: 0.5rem;
  position: relative;
  top: 0.1rem
}

.create-invoice-button{
  margin-left: 0.5rem; 
  background-color:#FFFFFF; 
  outline: none !important; 
  cursor:pointer; 
  border: 1px solid black;
  border-radius:2px
}

.create-invoice-button:hover{
  color: #263238;
  background-color: #e6e6e6;
}

.create-invoice-button:active{
  color: #263238;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad; 
}

.nav-tabs--custom{
  border-bottom: 1px solid #4a4a4a;
}
.nav-tabs--custom a.nav-link{
  color:rgba(128, 127, 127, 0.75);
}
.nav-tabs--custom a.nav-link.active,
.nav-tabs--custom a.nav-link.active:hover{
  border: 1px solid #4a4a4a;
  color:#4a4a4a;
  border-bottom: 1px solid #fff;
}
.nav-tabs--custom a.nav-link:hover{
  border: 1px solid rgba(128, 127, 127, 0.75);
  border-bottom: 1px solid #4a4a4a;
  color:#4a4a4a;
  cursor: pointer;
}

.tab-content--custom > .tab-content--custom__tab-pane{
  padding: 0px 15px 15px;
}

.tab-content--custom__card{
  margin: 0px -15px;
  border: 1px solid #4a4a4a;
  border-top:none;
}

.tab-content--custom__card .card .card-header .tittle {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.tab-content--custom__card .card .card-header button#upload-images {
  margin-top: 0;
  min-width: 128.5px;
}

.tab-content--custom__card .filter-container{
  margin-left: -1rem;
  margin-right: -1rem;
}
.sidebar .nav .nav-item.nav-dropdown .nav-dropdown-items .nav-link {
  font-size: 0.75rem;
  background-color:#182024
}
.sidebar .nav .nav-item.nav-dropdown .nav-dropdown-items .nav-link.active {
  background-color:#304047;
}

.field-row{
  display:flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.field-row .field-column{
  margin-bottom: 1rem;
}
.field-row hr{
  width: 100%;
}

.field-column .field-label{
  margin-bottom: .5rem;
}

.field-column .field-value{
  padding: .5rem 1rem;
  border:1px solid #ddd;
  min-height: 39px;
  width: 100%;
}
.field-column .field-value > span{
  display: block;
}
.field-column .field-value > span.break-all{
  word-break: break-all;
}

.field-column .field-value:hover{
  border:1px solid #8ad4ee;
}

select.form-control{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>");
  background-size: 10px;
  background-position: calc(100% - 10px) calc(50% + 2px);
  background-repeat: no-repeat;
  padding-right: 25px;
}
.modal-confirmation .modal-header--center .modal-title{
  flex:1;
  text-align: center;
}

#backToTopButton{
  position: fixed;
  border-radius: 1rem;
  bottom:0.5rem;
  right: 0.5rem;
  opacity: 0.5;
  display: none;
}
#backToTopButton.visible{
  display:block;
}
#backToTopButton:active, #backToTopButton:hover, #backToTopButton:focus{
  opacity: 1;
}

.badge.badge--thin{
  font-weight: normal;
  font-size: 70%;
}


.nav-item.nav-placeholder > .nav-placeholder__wrapper{
  height: 45px;
  display: flex;
  flex: 1;
  align-items: center;
}


.nav-item.nav-placeholder .nav-placeholder__icon{
  flex: 1;
  height: 1.5rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.nav-item.nav-placeholder .nav-placeholder__label{
  flex: 4;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.nav-item.nav-placeholder .nav-placeholder__label__top{
  flex: 1;
  width: 75%;
  height: 5px;
  margin-bottom: 5px;
}
.nav-item.nav-placeholder .nav-placeholder__label__bottom{
  flex: 1;
  height: 10px;
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}

.nav-placeholder__animated {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #304047 8%, #263238 18%, #304047 33%);
  background-size: 800px 104px;
  /* height: 96px; */
  position: relative;
}

.switch ~ .switch-label-span{
  margin-left: .5rem;
  margin-right: .5rem;
  margin-top: .2rem;
  margin-bottom: .5rem;
  display: inline-block;
}
.switch ~ .switch-label-wrapper{
  align-items: center;
  flex-direction: row;
  display: flex;
}

.switch-label-wrapper > .switch-label-span{
  margin-left: .5rem;
  margin-right: .5rem;
  margin-top: .2rem;
  margin-bottom: .5rem;
  display: inline-block;
}

.has-danger.form-group .Select-control{
  border: 1px solid #f86c6b;
}

.has-danger.form-group > label, .has-danger.form-group > .switch-label-span{
  color: #f86c6b;
}

.card.card__has-error{
  border: 1px solid #f86c6b;
}

.btn-upload{
  cursor: pointer;
  padding: 0px;
}
.btn-upload .label-upload{
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  padding: 0.5rem 1rem;
}
.btn-upload .label-upload .input-upload{
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.tooltip-inner.tooltip-check-title{
  font-size: 9px;
}

@media only screen and (max-width: 600px) {
  .main .breadcrumb{
    margin-bottom: .5rem;
  }
  .main > .container-fluid{
    padding: 0px .5rem;
  }
}
#loginAs > .dropdown-toggle {
  display: flex;
  align-items: center;
}
#loginAs > .dropdown-toggle > .initialName {
  border-radius: 45px;
  background: #ddd;
  width: 45px;
  display: flex;
  height: 45px;
  align-items: center;
}
#loginAs .initialName > strong {
  color: #4a4a4a;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}
#loginAs.show > .dropdown-menu{
  min-width: 182px;
  margin-top: 0.25rem;
  transform: translate3d(-123px, 45px, 0px) !important;
}

.input-group-prepend, .input-group-append {
  display: flex;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.bg-color-danger-custom {
  background-color: #FC5C02 !important;
}

