.request-form {
    height: auto;
}

.request-form /* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.request-form input[type="number"] {
    -moz-appearance: textfield;
}
