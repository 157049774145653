.consignment-detail {}

.consignment-detail .consignment-detail-upper-row {
    display: flex;
    align-items: center;
}

.consignment-detail .consignment-detail-upper-row .consignment-detail-upper-row__id {
    margin: 0;
    color: #c0c0c0;
}

.consignment-detail .consignment-detail-upper-row .consignment-detail-upper-row__consignment-id {
    margin: 0 0 0 2.5px;
}

.consignment-detail .consignment-detail-mid-upper-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.consignment-detail .consignment-detail-mid-upper-row .consignment-detail-mid-upper-row__display-name {
    margin: 0;
}

.consignment-detail .consignment-detail-mid-upper-row .consignment-detail-mid-upper-row-info {
    display: flex;
    align-items: center;
}

.consignment-detail .consignment-detail-mid-upper-row .consignment-detail-mid-upper-row-info .consignment-detail-mid-upper-row-info__sku {
    margin: 0;
    font-size: 0.8em;
    text-overflow: ellipsis;
}

.consignment-detail .consignment-detail-mid-upper-row .consignment-detail-mid-upper-row-info .consignment-detail-mid-upper-row-info__color {
    margin: 0 0 0 2.5px;
    color: #c0c0c0;
    font-size: 0.8em;
    text-overflow: ellipsis;
}

.consignment-detail .consignment-detail-mid-lower-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.consignment-detail .consignment-detail-lower-row {
    display: flex;
    align-items: center;
}

.shipping-method {
    vertical-align: middle !important;
}

.shipping-method .shipping-method-title {
    margin: 0;
}
.shipping-method .shipping-method-info {
    color: #c0c0c0;
    text-decoration: underline;
}