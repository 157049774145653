.product-card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 12px;
    margin-right: 24px;
    border-bottom: 1px solid #cfd8dc;
}

.product-card-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;
    border: 1px solid #000;
}

.desc-wrapper {
    width: 100%;
}

.desc-wrapper .cg-id {
    font-size: 11px;
}

.desc-wrapper .product-name {
    font-size: 12px;
    text-decoration: underline;
    max-width: 15vw;
}

.desc-wrapper .product-price {
    font-size: 10px;
    font-weight: bold;
}

.desc-wrapper .seller {
    margin-top: 4px;
    font-size: 10px;
}

.desc-wrapper .tags {
    display: flex;
    justify-content: flex-start;
    margin-top: 4px;
    max-width: 80%;
}

.desc-wrapper .tag-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #00add6;
    color: white;
    font-size: 10px;
    margin-right: 4px;
    height: 24px;
    width: 30%;
    text-align: center;
    padding: 0 4px;
}

.desc-wrapper .tag-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #00c76e;
    color: white;
    font-size: 10px;
    margin-right: 4px;
    height: 24px;
    width: 30%;
    text-align: center;
    padding: 0 4px;
}

.desc-wrapper .tag-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: white;
    font-size: 10px;
    border: 1px solid #cfd8dc;
    height: 24px;
    width: 30%;
    text-align: center;
    padding: 0 4px;
}

.tag-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.image-wrapper {
    height: 120px;
    border: 1px solid #676c6e;
    margin-right: 12px;
    display: flex;
    align-items: center;
}

.image-wrapper-form {
    height: 100%;
    margin-right: 12px;
}

.image-wrapper img {
    width: 120px;
    height: 90px;
    object-fit: contain;
}

.image-wrapper-form img {
    width: 120px;
}

.add-wrapper {
    margin: 0 auto;
}

.add-wrapper .button {
    width: 60px;
    background-color: black;
    color: white;
}

.add-wrapper .button-form {
    width: 60px;
}

.add-wrapper .button-selected {
    width: 60px;
}

.quantity-controls {
    display: flex;
    align-items: center;
    gap: 8px;
}

.quantity-button {
    padding: 4px 8px;
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantity-input {
    width: 60px;
    text-align: center;
    height: 32px;
    padding: 4px;
}

@media screen and (max-width: 480px) {
    .product-card-form {
        flex-direction: column;
    }
    
    .add-wrapper {
        margin-top: 12px;
    }
}