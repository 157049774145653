.estimated-profit-table tbody tr td:first-child {
  max-width: 250px;
}

.estimated-profit-table tbody tr td,
.purchase-summary-table tbody tr td {
  font-size: 0.875rem;
}

.estimated-profit-table tbody tr td p {
  margin: 0;
}

.estimated-profit-table tbody tr td p.bold {
  font-weight: bold;
}