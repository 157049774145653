.disabled {
    cursor: not-allowed !important;
}

.modal-select-product {
    max-width: fit-content;
    min-width: 70vw;
}

.modal-header {
    padding: 15px;
    padding-bottom: 0px;
    border-bottom: none;
}

.modal-select-product .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.modal-select-product .header-wrapper .input {
    width: auto;
}

.modal-select-product .header-wrapper .clear-filter {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
}

.modal-select-product .header-wrapper .right-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
}

.modal-select-product .header-wrapper .close-button {
    text-align: right;
    cursor: pointer;
}

.modal-select-product .sub-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.modal-select-product .product-list-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.modal-select-product .footer-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.full-width {
    width: 100%;
}

.desktop-only {
    display: initial;
}

.mobile-only {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: unset;
    }

    .modal-select-product .header-wrapper .right-wrapper {
        margin-top: 12px;
    }

    .modal-select-product .header-wrapper .close-button {
        position: absolute;
        right: 10px;
        top: 22px;
        cursor: pointer;
    }

    .modal-select-product .header-wrapper .clear-filter {
        margin-top: 12px;
    }

    .modal-select-product .body-wrapper {
        max-height: 50vh;
        overflow-y: scroll;
    }

    .modal-select-product .product-list-wrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
}
