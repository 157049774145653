.border-danger {
  border-color: #f86c6b;
}

.description-container-error :global(#cke_editor1) {
  border-color: #f86c6b;
}

.description-input :global(.public-DraftEditor-content) {
  min-height: 177px;
}
