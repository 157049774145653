.modal-delete-product-confirmation {
    width: 35vw;
    max-width: unset;
}

.modal-header {
    padding: 15px;
}

.modal-header h5{
    margin: 0;
}

.modal-delete-product-confirmation .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.modal-delete-product-confirmation .header-wrapper .close-button {
    flex-grow: 1;
    text-align: right;
    cursor: pointer;
}

.modal-delete-product-confirmation .header-wrapper .header-text {
    margin: 0;
}

.modal-delete-product-confirmation .footer-wrapper {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
